<template>
  <div>
    <v-container>
      <page-title title="客戶管理" />
    </v-container>
    <v-container class="py-0 mb-4">
      <!-- search -->
      <v-text-field
        dense
        outlined
        single-line
        hide-details
        clearable
        @keyup.enter="search"
        @click:clear="clearSearch"
        :placeholder="searchPlaceholder"
        prepend-inner-icon="ri-search-line"
        v-model="keyword"
        class="mb-4"
      ></v-text-field>
    </v-container>
    <v-container>
      <section v-if="searchMode">
        <template v-if="!searching">
          <template v-if="items.length == 0">
            <p>{{ "data.empty" | t }}</p>
          </template>
          <template v-else>
            <clientItem
              v-for="item in items"
              :data="item"
              :key="item.store_id"
            />
          </template>
        </template>
      </section>

      <section v-else>
        <overviewCard
          :indexType="
            $providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
          "
        />
        <overviewCard
          :indexType="
            $providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
          "
        />
        <overviewCard
          :indexType="
            $providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
          "
        />
      </section>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    overviewCard: () =>
      import("@/modules/provider/views/clientOverview/overviewCard.vue"),
    clientItem: () =>
      import("@/modules/provider/components/clientItem/clientItem.vue"),
  },
  data: () => ({
    keyword: "",
    items: [],
    searchMode: false,
    searching: false,
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    searchPlaceholder() {
      return this.$t("client.search_keyword");
    },
    params() {
      return {
        pager: 0,
        q: this.keyword,
      };
    },
  },
  mounted () {
    this.$root.gaLogEvent('供應商_客戶管理頁')
  },
  methods: {
    search() {
      this.index();
    },
    clearSearch() {
      this.searchMode = false;
    },
    async index() {
      this.$store.dispatch("loading/active");
      this.searching = true;
      this.searchMode = true;
      try {
        const res = await this.$api.collection.providerApi.clientIndex(
          this.providerId,
          this.params
        );
        this.items = res;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
        this.searching = false;
      }
    },
  },
};
</script>